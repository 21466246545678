import React from "react";
import ExploreImg from "../../../img/careers/explore.svg";
export default function Header() {
  return (
    <section className="carrers_header pt-5 pb-5">
      <div className="container">
        <div className="head mt-2">
          <h1>
            Explore oppurtunities at <span>CACTUS</span>
          </h1>
          <img src={ExploreImg} alt="" />
        </div>
        <div className="head_text mt-3">
          <p>
            At Cactus, our employees are our strongest asset. Our consultants
            are among the best in the industry. With high standards, integrity,
            and exceptional values we deliver on our promises as evidenced by
            our client relationships. We are continually searching for experts
            in the industry to join our team. We offer a competitive
            compensation package and rewards plan for qualifying candidates. At
            Cactus, we work hard to help you achieve an impressive career, while
            maintaining a positive work/life balance. We also believe that
            personal growth is extremely important; therefore we offer many ways
            to keep your skills sharp and up to date. We offer a wide variety of
            permanent and contract career opportunities, including IT and
            Product Design, with placement at leading companies in the United
            States and abroad.
          </p>
        </div>
      </div>
    </section>
  );
}
