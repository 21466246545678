import React from "react";
import "../../css/style.css";
import Header from "../components/home/header";
import MoreResons from "../components/home/moreResons";
import Growtogether from "../components/home/growtogether";
import Services from "../components/home/services";
export default function Home() {
  return (
    <>
      <Header />
      <Services />
      <MoreResons />
      <Growtogether />
    </>
  );
}
