import React from "react";
import WhyChooseus from "../components/services/application_development/WhyChooseus";
import Details from "../components/services/application_development/Details";
import Header from "../components/services/application_development/Header";
export default function ApplicationDev() {
  return (
    <>
      <Header />
      <WhyChooseus />
      <Details />
    </>
  );
}
