import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
export default function ContactusForm() {
  const [ismailsent, setIsmailsent] = useState(undefined);
  const [isformloading, setIsformloading] = useState(false);
  // useRef
  const nameele = useRef(null);
  const emailele = useRef(null);
  const phonenoele = useRef(null);
  const messele = useRef(null);

  const handlesubmit = (e) => {
    e.preventDefault();
    setIsformloading(true);

    const formdata = new FormData();
    formdata.append("name", nameele.current.value);
    formdata.append("email", emailele.current.value);
    formdata.append("mess", messele.current.value);
    formdata.append("phoneno", phonenoele.current.value);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_FORM_FETCH_API}/api/contactus`,
      requestOptions
    )
      .then((res) => {
        if (res.ok) {
          setIsformloading(false);
          toast.success("Form submited we will be right back!");
          return res.json();
        } else {
          setIsformloading(false);
          if (res.status === 400) {
            toast.error("enter vaild email");
          }
          if (res.status === 404 || res.status > 500) {
            toast.error("Something went wrong");
          }
        }
      })
      .then((data) => {})
      .catch((e) => {
        setIsmailsent(false);
        setIsformloading(false);
        toast.error("Something went wrong");
      });
  };
  return (
    <>
      <section className="contact_form" id="form">
        <div className="container">
          <div className="text">
            <h2 className="mb-1">Let's talk about what's next for you</h2>
            <p className="mb-1">
              Whether it's advancing innovation, pushing digital initiatives or
              bringing a talented technology team aboard to help make an impact
              quickly, our experts can help.
            </p>
          </div>
          <form className="form" onSubmit={handlesubmit}>
            <input
              type="text"
              placeholder="Name*"
              name="name"
              required
              ref={nameele}
            />
            <input
              type="text"
              placeholder="E-mail*"
              name="email"
              required
              ref={emailele}
            />
            <input
              type="text"
              placeholder="Phone No*"
              name="phoneno"
              required
              ref={phonenoele}
            />
            <input type="text" placeholder="Role" name="role" id="rolenone" />
            <textarea
              name="mess"
              placeholder="Message"
              rows="10"
              className="mt-1"
              ref={messele}
            ></textarea>

            <button type="submit" className="btn mt-2">
              Submit {isformloading && <i class="fas fa-spinner"></i>}
            </button>
          </form>
          {/* {ismailsent ? (
            <h3>Form sent! we will be right back</h3>
          ) : (
            ismailsent === false && <p>something went wrong</p>
          )} */}
        </div>
      </section>
    </>
  );
}
