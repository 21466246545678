import React from "react";
import itplanningImg from "../../../../img/service/consulting_services/it_planing.svg";
import careImg from "../../../../img/service/consulting_services/careing.svg";
import expertImg from "../../../../img/service/consulting_services/Expert.svg";
export default function Details() {
  return (
    <>
      <section className="details">
        <div className="alternative_cards">
          <div className="container">
            <img src={itplanningImg} alt="" id="imgpadding" />
            <div className="text">
              <h2 className="section_heading">Planning your IT strategy</h2>
              <p className="mt-1">
                IT strategy is frequently devised and then forgotten about for a
                year. An IT strategy is for all firms, regardless of size. Your
                IT strategy must be aligned with all departments inside an
                organization and serve as the business's blueprint. This has
                never been more true than it is now, as system complexity grows
                and firms face new constraints and obstacles on a daily basis.
                Leveraging your IT investments is a crucial component of any IT
                strategy, and it should no longer be treated as a cost center.
              </p>
            </div>
          </div>
          <div class="custom-shape-divider-top-1631973024 bottom hide-for-mobile">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </div>

        <div className="alternative_cards reverse">
          <div className="container">
            <div className="text">
              <h2 className="section_heading">What do we take care of?</h2>
              <p className="mt-1">
                As part of our professional service agreements, we provide you
                with the guidance you require, such as system reviews, issue or
                improvement identification, and the development of a tactical
                plan to improve your technology infrastructure.
              </p>
              <p className="mt-1">
                As you require it, assistance will be provided. Cactus will
                collaborate with your existing IT team and available resources
                to provide specialized technical support when your team requires
                it. Our on-site support service is a cost-effective strategy
                that results in faster scalability for your business while
                maintaining your high quality and reliability standards.
              </p>
            </div>
            <img src={careImg} alt="" />
          </div>
        </div>

        <div className="alternative_cards pt-2 pb-2">
          <div className="container">
            <img src={expertImg} alt="" />
            <div className="text">
              <h2 className="section_heading">Consult with an Expert</h2>
              <p className="mt-1">
                Our team of experts will work together to develop a strategic
                plan that will improve the business and your objectives by
                customizing a solution that is unique to your firm.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
