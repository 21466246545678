import React from "react";
import GetInTouch from "../components/contactus/getintouch";
import ContactusForm from "../components/contactus/form";
import Address from "../components/contactus/address";
export default function ContactUs() {
  return (
    <>
      <GetInTouch />
      <ContactusForm />
      <Address />
    </>
  );
}
