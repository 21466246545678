import React from "react";
import RecruitmentImg from "../../../img/home/services/recruitment.png";
import ApplicationImg from "../../../img/home/services/application.png";
import ConsultingImg from "../../../img/home/services/consulting.png";
import { Link } from "react-router-dom";
export default function Services() {
  return (
    <>
      <section className="services mt-5 mb-5" id="services">
        <div className="container">
          <div className="cards">
            <div className="card">
              <Link to={"/recruitment"}>
                <img src={RecruitmentImg} alt="" />
                <div className="text">
                  <h3>Recruitment Services</h3>
                  <p className="mt-1">
                    Staff Augmentation Services helps our clients to utilize
                    skilled IT personnel to help fill specific roles within the
                    organization.
                  </p>
                </div>
              </Link>
            </div>
            <div className="card">
              <Link to={"/application_development"}>
                <img src={ApplicationImg} alt="" />
                <div className="text">
                  <h3>Application Development</h3>
                  <p className="mt-1">
                    Our professionals experienced in cutting edge technologies
                    provide future proof solutions in most economical and
                    effective way
                  </p>
                </div>
              </Link>
            </div>
            <div className="card">
              <Link to={"/consulting"}>
                <img src={ConsultingImg} alt="" className="mb-2" />
                <div className="text">
                  <h3>Consulting Services</h3>
                  <p className="mt-1">
                    Cactus Solutions Consulting Services developed successful
                    methodologies to deliver custom and packaged project
                    solutions in implementing its projects.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
