import React from "react";
import future from "../../../../img/service/app_development/future.png";
import devops from "../../../../img/service/app_development/devops.png";
import techstack from "../../../../img/service/app_development/techstack.png";
export default function WhyChooseus() {
  return (
    <>
      <section className="why_choose_us mb-5">
        <div className="container">
          <h1 className="section_heading mb-5">Why choose us?</h1>
          <div className="cards mt-1">
            <div className="card">
              <img src={future} alt="" />
              <div className="text">
                <h4 className="mt-1">FutureProof Solution</h4>
                <p className="mt-1">
                  Our cutting-edge software developers offer future-proof
                  solutions in the most cost-effective and productive way
                  possible.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={techstack} alt="" />
              <div className="text">
                <h4 className="mt-1">Tech Stack</h4>
                <p className="mt-1">
                  We believe in standards and operating on the latest tech stack
                  and yet being efficient. We partner with industry-leading
                  experts to find out the best tech stack for your operations
                  and deliver the products without any discrepancies in quality
                  and security.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={devops} alt="" />
              <div className="text">
                <h4 className="mt-1">
                  Do you already have a legacy application that needs modern
                  touchups?
                </h4>
                <p className="mt-1">
                  Don’t you worry we got it covered !! We have industry-leading
                  experts who are going to help you rebuild the platform from
                  the ground up using modern agile and DevOps processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
