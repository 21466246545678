import React from "react";
export default function Header() {
  return (
    <>
      <section className="page_header cons_header">
        <div className="brand-heading">
          <div className="container">
            <div className="text">
              <h1 className="heading">Consulting Services</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
