import React from "react";
import getintouchImg from "../../../img/contactus/getintouch.svg";
export default function GetInTouch() {
  return (
    <>
      <section className="getintouch">
        <div className="container">
          <h1>Get In Touch</h1>
          <img src={getintouchImg} alt="" />
        </div>
      </section>
    </>
  );
}
