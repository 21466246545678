import React from "react";
import yourselfImg from "../../../img/careers/findyourself.svg";
import hiringImg from "../../../img/careers/hiringprocess.svg";
import careersImg from "../../../img/careers/careerpath.svg";
export default function Cards() {
  return (
    <>
      <section className="careers_cards">
        <div className="container">
          <div className="career_card">
            <h2>Find Yourself at Cactus</h2>
            <img src={yourselfImg} alt="" className="mt-2" />
            <p className="mt-2">
              For those who never stop learning. Cactus Solutions has a career
              path for you. Work with people who value innovation and insight.
              Cactus Solutions is a place where your drive and talent can take
              you far.
            </p>
          </div>
          <div className="career_card">
            <h2>Our Hiring Process</h2>
            <img src={hiringImg} alt="" className="mt-2" />
            <p className="mt-2">
              Cactus Solutions recruiters are here to guide you in the right
              direction. Work with top-tier talent and with clients who are
              focused on innovation. Expand your skills.
            </p>
          </div>
          <div className="career_card">
            <h2>Career Path</h2>
            <img src={careersImg} alt="" className="mt-2" />
            <p className="mt-2">
              Work with new technologies and growing organizations. Find your
              way on a clear path to career growth.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
