import React from "react";
export default function Header() {
  return (
    <>
      <section className="app_header page_header">
        <div className="brand-heading">
          <div className="container">
            <div className="text">
              <h1 className="heading">
                Application Development and Management
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
