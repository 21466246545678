import React from "react";
import anywhareImg from "../../../img/anywhare_recruting.svg";
import assistedImg from "../../../img/assisted_onboarding.svg";
import expertadviceImg from "../../../img/expertadvice.svg";
import fastGlobalImg from "../../../img/fastGlobalsupport.svg";
import { Link } from "react-router-dom";
export default function MoreResons() {
  return (
    <>
      <section className="brand-detail" id="moreresons">
        <div className="custom-shape-divider-top-1630399059 hide-for-mobile">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="container">
          <div className="section-title mb-3">
            <h2>Reasons to choose Cactus</h2>
          </div>
          <div className="card">
            <img src={fastGlobalImg} alt="" />
            <div className="text">
              <h3 className="mb-1">Fast global support</h3>
              <hr />
              <h4 className="mt-1">
                Reach our award-winning support team in about 25 seconds by
                phone or chat.
              </h4>
            </div>
          </div>

          <div className="card card-reverse">
            <img src={anywhareImg} alt="" />
            <div className="text">
              <h3 className="mb-1">Literally From Anywhere</h3>
              <hr />
              <h4 className="mt-1">
                We have the subject matter expertise, proven hiring methodology,
                and specialist talent to successfully solve your business
                technology business needs. We can meet your just-in-time
                resource and project requirements from anywhere. Thanks to our
                extensive sourcing channels and expert talent management. We are
                dedicated to providing brighter futures for both the
                organizations we work with and the people we place in new
                positions.
              </h4>
              <h4 className="mt-1">
                Our team is composed of true specialists who are committed to
                thorough understanding of their respective fields and dedication
                towards business partner needs.
              </h4>
              <Link to="/recruitment">
                <h4 className="link mt-1">Learn more {">"}</h4>
              </Link>
            </div>
          </div>

          <div className="card">
            <img src={expertadviceImg} alt="" />
            <div className="text">
              <h3 className="mb-1">Skilled Advice</h3>
              <hr />
              <h4 className="mt-1">
                Our team has extensive experience in their respective fields.
                Our personnel are trained to be proactive rather than reactive,
                to listen, to advise, and also provide exceptional recruitment
                services. We accomplish this by establishing communities based
                on the technologies and roles associated with each discipline.
              </h4>
              <h4 className="mt-1">
                The communities are made up of two overlapping groups:
                professionals looking for their next career move and employers
                looking to build exceptional IT teams.
              </h4>
              <Link to="/consulting">
                <h4 className="link mt-1">Learn more {">"}</h4>
              </Link>
            </div>
          </div>

          <div className="card card-reverse">
            <img src={assistedImg} alt="" />
            <div className="text">
              <h3 className="mb-1">Looking for jobs? We can help</h3>
              <hr />
              <h4 className="mt-1">
                Whether you’re a recent grad or an experienced individual
                looking to make your next career move, get in touch with one of
                our associates. Our experienced recruiters can help you find a
                role that matches your skills and experience and ensures the
                workplace has the right culture for you.
              </h4>
              <h4 className="mt-1">
                <Link to={"/careers"} className="link">
                  See our current openings.
                </Link>{" "}
                If they don’t match what you’re looking for,{" "}
                <Link to={"/contactus"} className="link">
                  send us your contact
                </Link>{" "}
                and we’ll get in touch to find a suitable role.
              </h4>
            </div>
          </div>
        </div>
        <div className="custom-shape-divider-top-1630399059 bottom hide-for-mobile">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </section>
    </>
  );
}
