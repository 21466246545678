import React from "react";
import Details from "../components/services/Recruitment/Details";
import RecruitmentInfo from "../components/services/Recruitment/RecruitmentInfo";
export default function Recruitment() {
  return (
    <>
      <RecruitmentInfo />
      <Details />
    </>
  );
}
