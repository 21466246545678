import React from "react";
import usmap from "../../../img/usmap.svg";
export default function Growtogether() {
  return (
    <>
      <section className="grow">
        <div className="container mt-5 mb-5">
          <img src={usmap} alt="" />
          <div className="text">
            <h3>Let’s grow together</h3>
            <p className="mt-1">
              Cactus helps companies of all sizes hire at scale. Start today by
              requesting a demo or posting a job for free to discover how Cactus
              can help you find and hire great people.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
