import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import React from "react";

export default function ResMap() {
  const position = [42.45155, -83.24652];
  return (
    <MapContainer
      center={position}
      zoom={12}
      scrollWheelZoom={false}
      // style={{ width: "500px", height: "400px" }}
      className="address_map"
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  );
}
