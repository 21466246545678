import React from "react";
import RecognizingImg from "../../../../img/service/app_development/Recognizing.svg";
import cloudImg from "../../../../img/service/app_development/cloud.svg";
export default function Details() {
  return (
    <>
      <section className="details">
        <div className="alternative_cards">
          <div className="container">
            <img src={RecognizingImg} alt="" />
            <div className="text">
              <h2 className="section_heading">
                Recognizing the target audience
              </h2>
              <p className="mt-1">
                Even critical business operations have become remote as a result
                of the current pandemic situation. As a matter of fact, you must
                ensure that your application is fully responsive to your target
                audience. We understand how a good application brings value to
                your business. Here at Cactus, we strive to give you custom
                solutions that are user-friendly with no loss of interface or
                functionality. As an agile company, we understand that
                requirements change rapidly and we’ll remain open for
                suggestions until the last minute delivery.
              </p>
            </div>
          </div>
          <div class="custom-shape-divider-top-1631973024 bottom hide-for-mobile">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </div>

        <div className="alternative_cards reverse">
          <div className="container">
            <div className="text">
              <h2 className="section_heading">
                Need help moving to Cloud? No problem
              </h2>
              <p className="mt-1">
                We have experience in rebuilding and modernizing technology
                organization, that has a monolithic data center with manual
                processes to a modern DevOps-based Micro-Services with single
                click deploys to the cloud (AWS). Thus increasing the deployment
                velocity by 8x while reducing a significant amount of outages
                and improved cost savings.
              </p>
            </div>
            <img src={cloudImg} alt="" />
          </div>
        </div>
      </section>
    </>
  );
}
