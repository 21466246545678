import React, { useState } from "react";
import Applynow from "../components/careers/applynow";
import Cards from "../components/careers/cards";
import Header from "../components/careers/Header";
import Opportunites from "../components/careers/opportunites";
export default function Careers() {
  const [jobrole, setJobrole] = useState("");
  return (
    <>
      <Header />
      <Cards />
      <Opportunites setrole={setJobrole} />
      <Applynow role={jobrole} setrole={setJobrole} />
    </>
  );
}
