import React from "react";

export default function Header() {
  return (
    <>
      <section className="about-header pt-5">
        <div className="container">
          <div className="about-heading">
            <h1>
              We are <span className="unq">CACTUS !</span>
            </h1>
          </div>
          <h2 className="mt-3">
            Cactus partners with organizations to develop technology strategies
            and solutions that deliver tangible business value.
          </h2>
        </div>
      </section>
    </>
  );
}
