import {
  Home,
  About,
  errorpage404,
  ContactUs,
  ApplicationDev,
  Recruitment,
  Careers,
  Consulting_services,
} from "./home/pages/index";
import { Nav, Footer } from "./home/components/index";
import ScrollToTop from "./hooks/scrolltotop";
import CustomSwitch from "./hooks/loaderhook";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Nav />
        <CustomSwitch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/application_development" component={ApplicationDev} />
          <Route path="/recruitment" component={Recruitment} />
          <Route path="/careers" component={Careers} />
          <Route path="/consulting" component={Consulting_services} />
          <Route path="/error" component={errorpage404} />
          <Redirect from="*" to="/error" />
        </CustomSwitch>
        <Footer />
        <Toaster />
      </Router>
    </>
  );
}

export default App;
