import React from "react";
import Header from "../components/services/consulting_services/Header";
import Details from "../components/services/consulting_services/Details";
export default function Consulting_services() {
  return (
    <>
      <Header />
      <Details />
    </>
  );
}
