import React from "react";
import ResMap from "./map";
export default function Address() {
  return (
    <>
      <section className="contact_address pt-5 pb-5">
        <div className="container">
          <div className="text">
            <h1>Registered Office</h1>
            <h3 className="mt-1 mb-1">20708 Midway Ave Southfield, MI 48075</h3>
            <a href="mailto:info@cactussolutions.org">
              <i class="far fa-envelope"></i> info@cactussolutions.org
            </a>
          </div>
          <ResMap />
        </div>
      </section>
    </>
  );
}
