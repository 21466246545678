import React from "react";

export default function RecruitmentInfo() {
  return (
    <>
      <section className="info">
        <div className="container">
          <h1>How do you get better workforce?</h1>
          <h4>
            In a competitive market, how can you attract and keep top talent? We
            take the responsibility to find high-performance candidates at
            Cactus by evaluating not just their skills and strengths, but also
            their interests and priorities. Our team ensures that the right
            candidates have the right jobs and make the workplace more
            enjoyable. We bear in our mind that our candidates are our assets
            and we support them in every possible way we can. For you, this
            guarantees a more productive and collaborative workforce.
          </h4>
        </div>
      </section>
    </>
  );
}
