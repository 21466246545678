/* eslint-disable */
import React from "react";
import logo from "../../img/logo.svg";
import { Link } from "react-router-dom";
export default function Footer() {
  const date = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <div className="external-links">
          <div className="services grid">
            <h4>Services</h4>
            <Link to={"/application_development"}>Application devlopment</Link>
            <Link to={"/recruitment"}>Recruitment Services</Link>
            <Link to={"/consulting"}>Consulting Services</Link>
          </div>
          <div className="aboutus grid">
            <Link
              style={{ color: "white", opacity: "1", marginTop: "0" }}
              to={"/about"}
            >
              <h4>About Us</h4>
            </Link>
            <Link to={"/careers"}>Careers at Cactus</Link>
          </div>
          <div className="contactus grid">
            <Link
              style={{ color: "white", opacity: "1", marginTop: "0" }}
              to={"/contactus"}
            >
              <h4>Contact Us</h4>
            </Link>
          </div>
        </div>
        <div className="about-company">
          <a href="" className="logo">
            <img src={logo} alt="" />
            <h2 className="ml-1">CACTUS</h2>
          </a>
          <div className="social-links">
            <h4>Follows us</h4>
            <a href="https://www.facebook.com/Cactus-Solutions-108999054848265">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com/CactusSolution1">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.linkedin.com/company/cactus-solutions-llc/about/">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://www.instagram.com/cactus.solutions/">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>

        <div className="terms">
          <p>Use of this service and this website constitutes acceptence of</p>
          <a href="">Privacy policy</a> | <a href="">Terms and Conditions</a>
          <hr />
          <p>
            © {date} Cactus Solutions, LLC. All Rights Reserved. CACTUS and its
            associated logos are trademarks of Cactus Solutions, LLC.
          </p>
        </div>
      </div>
    </footer>
  );
}
