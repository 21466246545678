import React, { useState, useEffect, useRef } from "react";
import logo from "../../img/logo.svg";
import { HashLink as Link } from "react-router-hash-link";

export default function Nav() {
  const [scrollPosition, setScrollPosition] = useState();
  const navref = useRef(null);
  const navtoggleref = useRef(null);
  const navtoggle = () => {
    const navt = navtoggleref.current;
    navt.classList.toggle("nav-toggle-hide");
  };
  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };
  const links = () => {
    return (
      <>
        <Link to={"/about"}>Why cactus</Link>
        <Link to={"/#services"} className="dropdown">
          Services
          <ul className="dropdown_content hide-for-mobile">
            <Link to={"/recruitment"}>
              <li>Recruitment Services</li>
            </Link>
            <Link to={"/application_development"}>
              <li>Application Development</li>
            </Link>
            <Link to={"/consulting"}>
              <li>Consulting Services</li>
            </Link>
          </ul>
        </Link>
        <Link to={"/careers"}>Careers</Link>
        <Link to={"/contactus"}>Contact Us</Link>
        <Link to={"/careers#applynow"} className="last btn">
          Apply Now
        </Link>
      </>
    );
  };
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      const nav = navref.current;
      if (scrollPosition > 50) {
        nav.classList.add("nav-scrolled");
      } else {
        nav.classList.remove("nav-scrolled");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPosition]);
  return (
    <nav>
      <div className="nav hide-for-mobile" ref={navref}>
        <div>
          <Link to={"/"} className="logo" onClick={() => scrolltotop()}>
            <img src={logo} alt="" className="mr-1" />
            <h2>CACTUS</h2>
          </Link>
        </div>
        <div className="links">{links()}</div>
      </div>

      <div
        className="nav-mobile hide-for-desktop"
        onClick={() => scrolltotop()}
      >
        <Link to={"/"} className="logo">
          <img src={logo} alt="" />
        </Link>
        <Link to={"/"}>
          <h2>CACTUS</h2>
        </Link>
        <i className="fas fa-bars" onClick={() => navtoggle()}></i>
      </div>
      <div
        className="nav-toggle hide-for-desktop nav-toggle-hide"
        ref={navtoggleref}
      >
        <div className="close">
          <i class="fas fa-times" onClick={() => navtoggle()}></i>
        </div>
        <div className="links" onClick={() => navtoggle()}>
          {links()}
        </div>
      </div>
    </nav>
  );
}
