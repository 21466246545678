import React from "react";
import { Link } from "react-router-dom";
import RecognizingImg from "../../../../img/service/recruitment/rec.png";
import screeningImg from "../../../../img/service/recruitment/screening.svg";
export default function Details() {
  return (
    <>
      <section className="details">
        <div className="alternative_cards">
          <div class="custom-shape-divider-top-1631973024 hide-for-mobile">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
          <div className="container">
            <img src={RecognizingImg} alt="" id="imgpadding" />
            <div className="text">
              <h2 className="section_heading">
                We can deliver a tailored strategy for your needs
              </h2>
              <p className="mt-1">
                We partner with organizations to develop or re-engineer their
                Talent Strategy. The goal is to attract, hire and retain the
                talent they need to accomplish the mission of the organization.
                Our specialty aligns in understanding the company goals as well
                as the talent market, recruiting ecosystem, and talent
                engagements.
              </p>
            </div>
          </div>
          <div class="custom-shape-divider-top-1631973024 bottom hide-for-mobile">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </div>

        <div className="alternative_cards reverse">
          <div className="container">
            <div className="text">
              <h2 className="section_heading">
                Yes, we do the screening for you!!
              </h2>
              <p className="mt-1">
                We believe in giving the best for our clients while
                understanding their talent needs. We make the process more
                seamless by leveraging the core principles of recruiting. The
                screening process that we apply ensures the candidate’s passion,
                skills, and experience will fit into your company’s culture.
                It’s not so much the answer but how it’s delivered that tells
                the most about each applicant.
              </p>
            </div>
            <img src={screeningImg} alt="" />
          </div>
        </div>
      </section>
      <section className="contact_us_rec pt-5 pb-5">
        <div className="container">
          <h1>
            Let’s start by defining your recruitment goal and we deliver it for
            you!!
          </h1>
          <br />
          <h2>
            Want to get in touch with us?{" "}
            <Link to={"/contactus"}>Shoot an email or call us.</Link>
          </h2>
        </div>
      </section>
    </>
  );
}
