import React from "react";
import { HashLink as Link } from "react-router-hash-link";
export default function Header() {
  return (
    <>
      <section className="header">
        <div className="brand-heading">
          <div className="container">
            <div className="text">
              <h1 className="heading">Cactus will never ever desert you</h1>
              <h4 className="sub-heading">
                With our cloud first strategies, unique accelerators and focused
                talent services, we make your future possible.
              </h4>
              <Link to={"/about"}>
                <button className="btn btn-secondary">Learn More</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
