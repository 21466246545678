import React from "react";
import img404 from "../../img/404.svg";
export default function errorpage404() {
  return (
    <div
      style={{
        paddingTop: "10rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={img404} alt="" style={{ maxWidth: "300px" }} />
      <h2 className="mt-2 mb-2">page not found</h2>
    </div>
  );
}
