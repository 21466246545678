import React from "react";
import currentImg from "../../../img/careers/currentopportunites.svg";
export default function Opportunites({ setrole }) {
  const applyNowLink = "/careers#applynow";
  const setrolefunc = (e) => {
    setrole(e.currentTarget.innerHTML);
  };
  return (
    <section className="opportunites mt-5 mb-5">
      <div className="container">
        <div className="adjest">
          <div className="links">
            <h1 className="opp_head">Current Opportunites</h1>
            <div className="opp_links mt-3">
              <ul>
                <a href={applyNowLink}>
                  <li onClick={(e) => setrolefunc(e)}>Java Developer</li>
                </a>
                <a href={applyNowLink}>
                  <li onClick={(e) => setrolefunc(e)}>Salesforce</li>
                </a>
                <a href={applyNowLink}>
                  <li onClick={(e) => setrolefunc(e)}>Embedded Engineer</li>
                </a>
                <a href={applyNowLink}>
                  <li onClick={(e) => setrolefunc(e)}>Hadoop</li>
                </a>
                <a href={applyNowLink}>
                  <li onClick={(e) => setrolefunc(e)}>React Developer</li>
                </a>
                <a href={applyNowLink}>
                  <li onClick={(e) => setrolefunc(e)}>UI/UX Designer</li>
                </a>
              </ul>
            </div>
          </div>
          <img src={currentImg} alt="" />
        </div>
      </div>
    </section>
  );
}
