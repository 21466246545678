import React from "react";
import Header from "../components/about/header";
import Aboutus from "../components/about/aboutus";
export default function About() {
  return (
    <>
      <Header />
      <Aboutus />
    </>
  );
}
