import React from "react";
import inovationsImg from "../../../img/about/inovation.svg";
import strongerImg from "../../../img/about/stronger.svg";
export default function Aboutus() {
  return (
    <>
      <section className="about-us pt-5 pb-5">
        <div className="container">
          <div className="aboutus-card">
            <div className="text">
              <h3 className="mb-2">At a Glance</h3>
              <p>
                We are a technology company providing a variety of services
                enabling companies across the globe, with their digital
                transformation journey. We are committed to bringing value to
                various industries using CloudOps, Data Analytics, and Modern
                Apps.
              </p>
              <p className="mt-1">
                We offer custom solutions based around expert abilities for all
                your complex requirements, so all aspects of your business
                benefit. At Cactus, we assist you with all your business
                Objectives and Performance metrics ensuring great customer
                satisfaction and endurance.
              </p>
              <p className="mt-1">
                We have confidence in Experience Advancement – a condition of
                steady improvement through customary knowledge-driven, gradual
                change. We work closely with our partners to understand their
                needs better thus ensuring to reach their goals.
              </p>
            </div>
          </div>
          <div className="aboutus-card mt-3">
            <div className="text">
              <h3 className="mb-2">We ask the right Questions</h3>
              <p className="mt">
                Lot of tech organizations are just request takers, missing to
                understand the business and complexity. This is a crucial stage
                in any Software Product or Web application project and we know
                it better.
              </p>
              <p className="mt-1">
                We ask our clients the right questions to guarantee that the
                innovation we wind up conveying isn't just built around the
                requirements but also future proof. The nature of the outcome is
                supported by our thirst for information about our client's
                business.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inovation pt-5 pb-3">
        <div className="container">
          <div className="card">
            <img src={inovationsImg} alt="" />
            <div className="text">
              <h3 className="mb-2">Innovation happens here</h3>
              <p>
                With ingenuity, creativity and real and virtual environments, we
                move objectives from ideation to reality. By applying innovation
                to advance your organization’s top initiatives, you not only get
                faster results, you can achieve digital business goals, provide
                a better customer experience or even beat your competition with
                improved service.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="stronger pb-5">
        <div class="custom-shape-divider-top-1631973024 bottom hide-for-mobile">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
              style={{ fill: "white" }}
            ></path>
          </svg>
        </div>
        <div className="container">
          <div className="card">
            <img src={strongerImg} alt="" />
            <div className="text">
              <h3 className="mb-1">Stronger together with Cactus</h3>
              <p>
                Whether it’s teaming across the enterprise to help clients
                leverage the newest emerging technologies, or providing Talent
                Services customized for individual, capacity, or managed
                services, Cactus Solutions is committed to providing clients
                with high-impact results every day.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
